import React from 'react';

export default function(__params = {}) {
  const {
    EducationList,
    InformationBlock,
    JobsList,
    LinksList,
    Skillset,
    education,
    images,
    information,
    jobs,
    links,
    menuLinks,
    skillsets
  } = __params;
  return (
    <>
      <div className="outer" id="header_wrap">
        <header className="inner">
          <h1 id="project_title">Óscar Gómez Alcañiz</h1>
          <h2 className="text-right" id="project_tagline">
            Curriculum Vitae
          </h2>
        </header>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" role="navigation">
          <a className="navbar-brand" href="#header_wrap">
            Óscar Gómez —<strong> CV</strong>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navegacion-desplegable"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navegacion-desplegable" style={{ justifyContent: 'flex-end' }}>
            <ul className="nav navbar-nav">
              {(menuLinks || []).map((link, i) => (
                <li className={'nav-item' + (i === 0 ? ' active' : '')} key={i}>
                  <a className="nav-link" href={link.href}>
                    <i className={`${link.icon[0]} fa-${link.icon[1]}`}>&ensp;</i>
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
      <div className="outer" id="main_content_wrap">
        <section className="inner jquery-anchor" id="contact">
          <img className="photo" src={images.me} alt="Profile picture" />
          {(information || []).map((infoblock, i) => (
            <span key={i}>
              <InformationBlock info={infoblock.info} title={infoblock.title}></InformationBlock>
            </span>
          ))}
        </section>
        <hr />
        <section className="inner jquery-anchor" id="experience">
          <h3>Work Experience</h3>
          <JobsList jobs={jobs}></JobsList>
        </section>
        <hr />
        <section className="inner jquery-anchor" id="education">
          <h3>Education</h3>
          <EducationList education={education}></EducationList>
        </section>
        <hr />
        <section className="inner jquery-anchor" id="skills">
          <h3>Skills</h3>
          {(skillsets || []).map((skillset, i) => (
            <Skillset key={i} skillset={skillset}></Skillset>
          ))}
        </section>
        <hr />
        <section className="inner jquery-anchor" id="examples">
          <h3>Work Examples</h3>
          <h4>Design</h4>
          <h5>Day Of Rising CD Artwork Design</h5>
          <h6>Original shots</h6>
          <img className="thumb" src={images.cdRaw1} alt="Raw Material 1" />
          <img className="thumb last" src={images.cdRaw2} alt="Raw Material 2" />
          <img src={images.cdRaw3} alt="Raw Material 3" />
          <h6>Results</h6>
          <img src={images.sampleCdFront} alt="CD Artwork / Front" />
          <img src={images.sampleCdBack} alt="CD Artwork / Back" />
          <h4>Experiments</h4>
          <h5>Observable</h5>
          <p>
            <a href="https://beta.observablehq.com/@oscardr">
              <i className="fas fa-dot-circle"></i> My Notebooks
            </a>
          </p>
          <h5>CodePen</h5>
          <p>
            <a href="https://codepen.io/OscardR/">
              <i className="fab fa-codepen"></i> My Pens
            </a>
          </p>
          <h4>Websites</h4>
          <ul>
            <li>
              <img
                className="icon"
                src="https://procurement.web.cern.ch/sites/procurement.web.cern.ch/themes/procurement/favicon.ico"
                alt="Icon"
              />
              <a href="https://cern.ch/procurement"> Procurement @ CERN</a>
            </li>
            <li>
              <img
                className="icon"
                src="https://knowledgetransfer.web.cern.ch/sites/knowledgetransfer.web.cern.ch/themes/kt/favicon.ico"
                alt="Icon"
              />
              <a href="https://kt.cern"> Knowledge Transfer @ CERN</a>
            </li>
            <li>
              <img className="icon" src="https://www.dayofrising.rocks/img/logo-white.png" alt="Icon" />
              <a href="https://www.dayofrising.rocks"> Day Of Rising</a>
            </li>
          </ul>
        </section>
      </div>
      <div className="outer" id="footer_wrap">
        <footer className="inner">
          <h3>Networking</h3>
          <LinksList links={links}></LinksList>
        </footer>
      </div>
    </>
  );
}
