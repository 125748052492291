import React from 'react';

export default function(__params = {}) {
  const { pairs, title } = __params;
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: title }}></div>
      <dl>
        {(pairs || []).map((pair, i) => (
          <span key={i}>
            <dt>{pair.key}</dt>
            {!pair.link ? (
              <dd>{pair.value}</dd>
            ) : (
              <dd>
                <a href={pair.link}>{pair.value}</a>
              </dd>
            )}
          </span>
        ))}
      </dl>
    </>
  );
}
