import React from 'react';

export default function(__params = {}) {
  const { education } = __params;
  return (education || []).map((school, i) => (
    <div className="education" key={i}>
      <h5 className="dates">
        {school.from} – {school.to !== 'Invalid date' ? school.to : 'Currently'}
      </h5>
      <h4>
        <span className="school">{school.title}</span>
        <small className="location"> | {school.location}</small>
      </h4>
      <div dangerouslySetInnerHTML={{ __html: school.body }}></div>
    </div>
  ));
}
