import React from 'react';

export default function(__params = {}) {
  const { jobs } = __params;
  return (jobs || []).map((job, i) => (
    <div className="job" key={i}>
      {job.from !== 'Invalid date' ? (
        <h5 className="dates">
          {job.from} &mdash; {job.to !== 'Invalid date' ? job.to : 'Currently'}
        </h5>
      ) : (
        ''
      )}
      <h4>
        <span className="company">{job.title}</span>
        {job.description ? <small className="location"> | {job.description}</small> : ''}
        {job.position ? (
          <div>
            <br />
            <small className="position">
              <em>{job.position}</em>
            </small>
          </div>
        ) : (
          ''
        )}
      </h4>
      <div dangerouslySetInnerHTML={{ __html: job.body }}></div>
    </div>
  ));
}
