import React from 'react';

export default function(__params = {}) {
  const { skillset } = __params;
  return (
    <div className="skill">
      <h4 className="field">{skillset.title}</h4>
      {skillset.body ? <div dangerouslySetInnerHTML={{ __html: skillset.body }}></div> : ''}
      <ul>
        {(skillset.skills || []).map((skill, i) => (
          <li key={i}>
            <h5 className="category">{skill.name}</h5>
            <ul>
              {(skill.items || []).map((item, i) => (
                <li key={i}>
                  {item.name}
                  <small>{' | ' + item.details.join(' / ')}</small>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
